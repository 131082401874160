import { SET_PRODUCT } from './-mutation-types';
import { useProductsStore } from '~/stores/products';
import type { Product } from '~/models/Product';

const Mutations = {
    [SET_PRODUCT](payload: Product | null) {
        const productsStore = useProductsStore();
        productsStore.product = payload;
    },
};

export default Mutations;
