/* eslint-disable vue/sort-keys */

import { defineStore } from 'pinia';
import { DefaultState } from './products/state';
import Getters from './products/getters';
import Actions from './products/actions';
import Mutations from './products/mutations';

export const useProductsStore = defineStore(
    'products',
    {
        state: DefaultState,
        getters: Getters,
        actions: {
            ...Actions,
            ...Mutations,
        },
    },
);
