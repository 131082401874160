import type { Product } from '~/models/Product';

export type ProductState = {
    product: Product | null;
}

export const defaultStateData = (): ProductState => ({
    product: null,
});

export const DefaultState = (): ProductState => defaultStateData();

export type State = ReturnType<typeof DefaultState>
