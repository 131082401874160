import { SET_PRODUCT } from './-mutation-types';
import { useProductsStore } from '~/stores/products';
import ProductService from '~/services/api/ProductService';
import type { Product } from '~/models/Product';

const Actions = {
    async fetchProduct(productId: number) {
        const productsStore = useProductsStore();
        const productService = new ProductService();
        const response = await productService.fetchProduct(productId);

        productsStore[SET_PRODUCT](response);
    },
    setProduct(product: Product) {
        const productsStore = useProductsStore();
        productsStore[SET_PRODUCT](product);
    },
};

export default Actions;
