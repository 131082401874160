import { GET_PRODUCT } from './-getter-types';
import { useProductsStore } from '~/stores/products';
import type { Product } from '~/models/Product';

const Getters = {
    [GET_PRODUCT]: (): Product | null => {
        const productsStore = useProductsStore();

        return productsStore.product;
    },
};

export default Getters;
